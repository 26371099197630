<template>
    <div :class="['alert', type]">
        <div :class="['bg-opacity', type]"></div>
        <div class="alert__icon">
            <IconSax :name="icon" type="outline" />
        </div>
        <div class="alert__content">
            <div class="alert__title body-semibold-14">
                {{ title }}
            </div>
            <div class="alert__description body-regular-14">
                {{ description }}
            </div>
        </div>
    </div>
</template>

<script>
import IconSax from "@/components/common/IconSax.vue";

export default {
    components: {
        IconSax,
    },
    props: {
        icon: {
            type: String,
            default: "info-circle",
        },
        type: {
            type: "warning" | "danger" | "success" | "neutral",
            default: "warning",
        },
        title: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>
.alert {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    position: relative;

    .bg-opacity {
        top: 0;
        left: 0;
        opacity: 0.15;
        position: absolute;
        width: 100%;
        height: 100%;

        &.warning {
            background-color: var(--feedback-warning-300);
        }

        &.danger {
            background-color: var(--feedback-danger-300);
        }

        &.success {
            background-color: var(--feedback-success-300);
        }

        &.neutral {
            background-color: var(--feedback-neutral-300);
        }
    }

    .alert__icon {
        margin-right: 0.5rem;
    }

    .alert__description {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: var(--neutral-gray-800);
    }

    &.warning {
        border: 1px solid var(--feedback-warning-400);

        .alert__icon {
            ::v-deep .svgCss {
                svg {
                    width: 1rem;
                    height: 1rem;
                }

                svg path {
                    fill: var(--feedback-warning-600) !important;
                }
            }
        }

        .alert__title {
            color: var(--feedback-warning-600);
        }
    }

    &.danger {
        border: 1px solid var(--feedback-danger-400);

        .alert__icon {
            ::v-deep .svgCss {
                svg {
                    width: 1rem;
                    height: 1rem;
                }

                svg path {
                    fill: var(--feedback-danger-600) !important;
                }
            }
        }

        .alert__title {
            color: var(--feedback-danger-600);
        }
    }

    &.success {
        border: 1px solid var(--feedback-success-400);

        .alert__icon {
            ::v-deep .svgCss {
                svg {
                    width: 1rem;
                    height: 1rem;
                }

                svg path {
                    fill: var(--feedback-success-600) !important;
                }
            }
        }

        .alert__title {
            color: var(--feedback-success-600);
        }
    }

    &.neutral {
        border: 1px solid var(--feedback-neutral-400);

        .alert__icon {
            ::v-deep .svgCss {
                svg {
                    width: 1rem;
                    height: 1rem;
                }

                svg path {
                    fill: var(--feedback-neutral-600) !important;
                }
            }
        }

        .alert__title {
            color: var(--feedback-neutral-600);
        }
    }
}
</style>